import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import Clients from "../components/Clients.js"
import Closer from "../components/Closer.js"

// eslint-disable-next-line
export const ClientPageTemplate = ({
  heading,
  description,
  subheader,
  clients,
  clientlist,
}) => {

  return (
    <div className="content" style={{
      backgroundImage: "url(/img/globe-bg.jpg)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    }}>
      <section className="section section--gradient">
        <div className="container">            
            <div className="columns">
              <div className="column is-5 is-offset-1">
                <h2 className="has-text-weight-semibold is-size-2" style={{
                  color: "#30CAFF",
                }}>
                  {heading}
                </h2>
                <p>{description}</p>
              </div>
              <div className="column">
              </div>
            </div>

            <div className="columns">
              <div className="column is-10 is-offset-1" style={{
                marginTop: "2em",
              }}>
                <div className="content has-text-centered">
                  <h2 style={{
                    color: "#A1DE1D",
                  }}>{subheader}</h2>
                </div>
                <Clients gridItems={clientlist.blurbs}/>
              </div>
            </div>
        </div>
      </section>
      <Closer />
    </div>
  );
};

ClientPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  subheader: PropTypes.string,
  clientlist: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const ClientPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <ClientPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheader={frontmatter.subheader}
        description={frontmatter.description}
        intro={frontmatter.intro}
        clientlist={frontmatter.clientlist}
        clients={frontmatter.clients}
        partners={frontmatter.partners}
        testimonials={frontmatter.testimonials}
        fullImage={frontmatter.full_image}
        pricing={frontmatter.pricing}
      />
    </Layout>
  );
};

ClientPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ClientPage;

export const partnerPageQuery = graphql`
  query ClientPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        subheader
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        description
        clientlist {
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(width: 500, quality: 100, layout: CONSTRAINED)
              }
            }
            header
          }
        }
      }
    }
  }
`;
